import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { Channel } from 'laravel-echo/dist/channel';
import echo from '@/components/Echo';
import Echo from 'laravel-echo';
import { setEchoStatus, setSocketId } from '@/redux/reducers/appSlice';
import userApi from '@/redux/features/userApi';
import { useTools } from '@/contexts/ToolsContext';

type EchoContextType = { echo: Echo | null; privateChannel: Channel | null };

interface EchoProps {
    children?: ReactNode;
}

export const EchoContext = createContext<EchoContextType>({ echo: null!, privateChannel: null! });

const EchoProvider = ({ children }: EchoProps) => {
    const disabled = import.meta.env.VITE_DISABLE_WEBSOCKETS === 'true';
    const auth = useAppSelector((state) => state.auth);
    const user = useAppSelector((state) => state.user);
    const socket = useAppSelector((state) => state.app.socket);
    const [privateChannel, setPrivateChannel] = useState<Channel | null>(null);
    const dispatch = useAppDispatch();
    const { playChime } = useTools();

    useEffect(() => {
        if (disabled) {
            return;
        }

        //We have auth but are not connected to WS
        if (auth.status && (auth.xsrfToken || auth.pkce.accessToken) && socket !== 'connected') {
            //user logged out or pusher disconnected, tell pusher to reconnect
            echo.connector.pusher.connection.state === 'disconnected' && echo.connector.pusher.connect();
        }
        //User Logged out/lost session
        if (!auth.xsrfToken && !auth.pkce.accessToken && socket === 'connected') {
            echo.leaveAllChannels();
            echo.connector.pusher.disconnect();
            setPrivateChannel(null);
        }
    }, [auth]);

    //Setup State changed listener
    useEffect(() => {
        if (disabled) {
            return;
        }

        const onStateChanged = ({ current, _previous }: { current: string; _previous: 'string' }) => {
            if (current === 'connected') {
                if (auth.xsrfToken || auth.pkce.accessToken) {
                    dispatch(setEchoStatus('connected'));
                }

                dispatch(setSocketId(echo.socketId()));
            }
        };
        echo.connector.pusher.connection.bind('state_change', onStateChanged);

        return () => {
            echo.connector.pusher.connection.unbind('state_change', onStateChanged);
        };
    }, []);

    //Connect to user's private channel once we are connected
    useEffect(() => {
        if (disabled || !user.data?.id) {
            return;
        }

        if (!privateChannel) {
            setPrivateChannel(echo.private(`intract.user.${user.data.id}`));
            return;
        }

        const invalidateUser = () => dispatch(userApi.util.invalidateTags(['User']));
        const invalidateOrganizations = () => dispatch(userApi.util.invalidateTags(['user-organizations']));

        const onNotification = () => {
            playChime();
            invalidateUser();
            invalidateOrganizations();
        };

        privateChannel
            .listen('.notification', onNotification)
            .listen('.user.archived', invalidateUser)
            .listen('.user.updated', invalidateUser)
            .listen('.user.subscription.updated', invalidateUser);

        return () => {
            privateChannel
                .stopListening('.notification', onNotification)
                .stopListening('.user.archived', invalidateUser)
                .stopListening('.user.updated', invalidateUser)
                .stopListening('.user.subscription.updated', invalidateUser);
            echo.leave(`intract.user.${user.data.id}`);
            setPrivateChannel(null);
        };
    }, [socket, privateChannel, user.data?.id]);

    return <EchoContext.Provider value={{ echo: echo, privateChannel }}>{children}</EchoContext.Provider>;
};

const useEcho = () => {
    return { ...useContext(EchoContext) };
};

const useEchoPrivateListener = () => {
    const { echo, privateChannel } = useContext(EchoContext);

    return useCallback(
        (channel: string, callback: Function) => {
            if (echo && privateChannel) {
                privateChannel.listen(channel, callback);

                return () => {
                    privateChannel.stopListening(channel);
                };
            } else {
                return () => {};
            }
        },
        [echo, privateChannel]
    );
};

export default EchoProvider;
export { useEcho, useEchoPrivateListener };
