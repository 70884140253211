import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { store } from '@/redux/store';
import { broadcasting } from '@/redux/features/authApi';

const disabled = import.meta.env.VITE_DISABLE_WEBSOCKETS === 'true';
const dispatch = store.dispatch;

const options = {
    broadcaster: disabled ? 'null' : 'reverb',
    client: disabled
        ? null
        : new Pusher(`${import.meta.env.VITE_PUSHER_APP_KEY}`, {
              cluster: 'NaN',
              wsPath: import.meta.env.VITE_PUSHER_APP_PATH,
              wsHost: import.meta.env.VITE_URI,
              wssPort: parseInt(import.meta.env.VITE_PUSHER_APP_WSS_PORT as string),
              wsPort: parseInt(import.meta.env.VITE_PUSHER_APP_WS_PORT as string),
              forceTLS: (import.meta.env.VITE_DEPLOY_ENV as string) !== 'local',
              enableStats: false,
              enabledTransports: ['ws', 'wss'],
              channelAuthorization: {
                  customHandler: ({ socketId, channelName }, callback) => {
                      dispatch(broadcasting.initiate({ socket_id: socketId, channel_name: channelName }))
                          .then((success) => {
                              if ('data' in success) {
                                  callback(null, success.data);
                              }
                          })
                          .catch((failed: BasicError) => callback(new Error(failed.data.message), null));
                  },
              },
          }),
};

let echo: Echo = new Echo(options);

const listener = () => {
    const auth = store.getState().auth;

    if ((auth.xsrfToken || auth.pkce.accessToken) && auth.status) {
        // console.log('store', auth);
        echo = new Echo(options);
    }
    if (!auth.status) {
        echo.disconnect();
    }
};

store.subscribe(listener);

export default echo;
